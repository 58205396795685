@import "../../App.scss";

.auth-container {
    padding-top: 50px;
    padding-bottom: 50px;

    .card {
        margin: auto;
        overflow: hidden;
        border-radius: 6px;

        .card-header {
            padding-top: 30px;
            padding-bottom: 30px;

            h4 {
                font-weight: 700;
            }
        }

        .card-body {
            .message-text {
                p {
                    color: $gray-600 !important;
                    font-size: 13px !important;

                    a {
                        font-size: 13px !important;
                        font-weight: 600 !important;
                    }
                }
            }
        }

        @media (min-width: 576px) {
            width: 500px;
        }
    }
}