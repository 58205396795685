.master {
    min-height: 100vh;

    .main {
        padding-top: 50px;

        @media (min-width: 992px) {
            padding-bottom: 20px;
            padding-left: 280px;
        }
    }
}