.security-login-container {

    // device-container
    .device-container {
        padding: 10px;

        p {
            font-size: 14px;
            line-height: 12px;
        }

        small {
            font-size: 12px;
        }
    }
}