@import "../../App.scss";

.data-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.438);

    .loader {
        width: 40px;
        height: 40px;
        margin: auto;
        border-radius: 50%;
        border: 1px solid #f3f3f3;
        border-top: 1px solid $primary;
        animation: spin 0.5s linear infinite;
        -webkit-animation: spin 0.5s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @media (min-width: 992px) {
        padding-left: 320px;
    }
}
