.settings-index {
    .accordion {
        .card {
            .card-header {
                .btn {
                    width: 100%;
                    box-shadow: none;
                    text-decoration: none;

                    h6 {
                        font-size: 17px;
                        color: #495057;
                    }

                    .icon {
                        color: #495057;
                        -moz-transition: all 0.3s linear;
                        -webkit-transition: all 0.3s linear;
                        transition: all 0.3s linear;
                    }

                    .down {
                        -moz-transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }

                    :focus {
                        box-shadow: none;
                    }
                }

            }
        }
    }

    .list-group {
        .list-group-item {
            a {
                text-decoration: none;
            }
        }
    }
}