@import "../../App.scss";

.shift-card{
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 5px;
  position: relative;
  min-width: 230px;
  height: 160px;

  .badge{
    font-size: 13px;
  }
  .edit-button{
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 20px;
    height: 40px;
    width: 40px;
  }
}
.create-card{
  cursor: pointer;
  text-align: center;

  .create-icon{
    margin-top: 45px;
  }

}

.nav-tabs {
    border: 0 !important;

    .nav-item {
        border: 0;
        color: #000;
        font-size: 13px;
        font-weight: 400;
        margin-right: 5px;
        padding: 7px 14px;
        margin-bottom: 0;
        background: #dfdfdf;
        border-radius: 4px;
        text-transform: capitalize;
    }

    .active {
        border: 0;
        color: #fff !important;
        border-radius: 4px;
        background: $primary !important;
    }
}
