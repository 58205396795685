

$primary:       #042893;
$secondary:     #063cdd; 
$success:       #198754;
$info:          #00cccc;
$warning:       #ffc107;
$danger:        #dc3545;
$pink:          #f10075;
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;