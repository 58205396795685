@import "../../App.scss";

.nav-tabs {
    border: 0 !important;

    .nav-item {
        border: 0;
        color: #000;
        font-size: 13px;
        font-weight: 400;
        margin-right: 5px;
        padding: 7px 14px;
        margin-bottom: 0;
        background: #dfdfdf;
        border-radius: 4px;
        text-transform: capitalize;
    }

    .active {
        border: 0;
        color: #fff !important;
        border-radius: 4px;
        background: $primary !important;
    }
}